.main {
    display: flex;
    justify-content: center;
    color: white;
    align-items: center;
    padding: 10px;
    font-size: 0.875rem;
}

.percentBox {
    border: 2px solid white;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 10px 0px 0px;
    padding: 5px;
}

.innerPercentBox {
    border: 2px solid white;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.875rem;
}

.pieContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 10px 0px 0px;
    padding: 5px;
} 

.storageDetailBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    color: black;
}