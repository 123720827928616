.placement {
    display: flex;
    justify-content: center;
}

.checkbox {
    width: 0.95rem !important;
    height: 0.95rem !important;
    min-width: 0.95rem !important;
}

.checkboxContainer {
    display: flex;
    gap: 0.25rem;
    align-items: center;
}

.actions {
    display: flex;
    align-items: center;
}